.loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
}