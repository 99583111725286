.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contact-title {
  text-align: center;
  margin-bottom: 20px;
}

.contact-form-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-right: 10px;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

.full-width {
  flex: 1;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.checkbox-label input {
  margin-right: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007BFF;
  color: #fff;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
  }

  .checkbox-label {
    flex-direction: column;
    align-items: flex-start;
  }
}