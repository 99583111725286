/* .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */


/* Header styling */
.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.rback-icon {
  font-size: 50px;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 24px;
}

.rback-icon:hover {
  color: #45a049;
}

.title {
  font-size: 2rem;
  color: #333;
  margin-top: 30px;
  margin-bottom: 0px;
}

.rseparator {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 5px auto;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch;
  /* Smooth scrolling on iOS */
}

.receipt-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 85px;
  margin-top: 25px;
}

.receipt-table th,
.receipt-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #333;
}

.receipt-table th {
  background-color: #20283E;
  color: white;
  font-weight: bold;
  /* border: none; */
  border: 1px solid whitesmoke;
}

.receipt-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.view-receipt-button {
  padding: 6px 12px;
  font-size: 14px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-receipt-button:hover {
  background-color: #007bff;
}


@media (max-width: 768px) {
  .receipt-table {
    min-width: 600px;
    /* Ensures the table does not shrink too much */
  }
}