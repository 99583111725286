/* ResetPassword.css */

.rscontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background: #f4f7f6;
}

.formWrapper {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 350px;
  max-width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.formWrapper:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.title {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
  text-align: left;
}

.input {
  padding: 12px;
  margin-bottom: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.2);
  outline: none;
}

.button {
  padding: 12px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.error {
  color: #e74c3c;
  font-size: 14px;
  margin-bottom: 16px;
}

.back-icon1 {

  font-size: 30px;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 31rem;

}

@media only screen and (max-width: 768px) {
  .rscontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background: white;
  }

  .formWrapper {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 288px;
    max-width: 100%;
    transition: transform 0.3s, box-shadow 0.3s;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
}