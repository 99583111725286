.contact-admin {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.contact-admin h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact-admin form div {
  margin-bottom: 15px;
}

.contact-admin label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-admin input,
.contact-admin textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.contact-admin button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 16px;
}

.contact-admin button:hover {
  background-color: #45a049;
}

.contact-admin p {
  text-align: center;
  color: #f00;
}

@media only screen and (max-width: 768px) {
  .contact-admin {
    max-width: 356px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}