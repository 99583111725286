div.input {
  padding: 16px;
  margin: 10px;
  margin-top: 10px;
  /* border-radius: 10px solid black; */
}

.input-popup {
  padding: 30px;
  justify-content: center;
  align-items: center;
}

.input-field::placeholder {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #9e9e9e;
}

.container {
  align-items: center;
  justify-content: center;
  margin: auto;
  width: full;
}

.custom-close-btn {
  background-color: transparent;
  border: none;
  opacity: 1;
}

.custom-close-btn::before {
  content: '\00d7';
  font-size: 1.5rem;
  color: white;
}

.custom-close-btn:hover::before {
  color: red;
}


.custom-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
}

.custom-modal .modal-content {
  width: 400px;
  padding: 0;
  height: 509px;
  margin: auto;

}

.custom-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.custom-button {
  background: linear-gradient(105deg, #6e99e6, #093c94);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-button:hover {
  background: linear-gradient(105deg, #093c94, #6e99e6);
}

.input-icon-wrapper {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.input-field:focus+.input-icon,
.input-field:hover+.input-icon {
  color: #797878;
}

.input-field {
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  padding-left: 40px;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}


textarea.input-field {
  height: 66px;
  line-height: 42px;

}

textarea.input-field::placeholder {
  line-height: 42px;
  color: #9e9e9e;
}