/* ===================== 404 ERROR ======================*/
.error-page-inn h2 {
    font-size: 200px;
    font-weight: 800;
    line-height: 94px;
    color: #000;
    letter-spacing: 25px;
    margin-top: -19rem;
}

.error-page-inn h2 span {
    color: #6e99e6
}

.btn-primary {
    border: 1px solid black;
    color: white;
    background-color: #6e99e6;
    height: 50px;
    width: 150px;
    text-align: center;
    display: inline-block;
    line-height: 50px;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none;
    padding: 0;
    box-sizing: border-box;
    margin-top: 15px;
}

.error-page-inn h3 {
    margin-top: 30px;
}

/*Responsive*/
@media(max-width: 767px) {
    .error-page-inn h2 {
        font-size: 76px;
        line-height: 60px;
        letter-spacing: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .error-page-inn h2 {
        font-size: 100px;
        line-height: 90px;
        letter-spacing: 10px;
    }
}