.marasandra-page {
  padding: 0;
}

.banner-marasandra {
  background-image: url("../../images/HR-2.webp");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 0;
  position: relative;
  /* height: 400px; */
}

.banner-content-marasandra {
  /* background: rgba(0, 0, 0, 0.5); */
  display: inline-block;
  padding: 0px 0px 0px 120px;
}

.seals {
  /* height: 130px;
    width: 130px; */
  width: 100%;
}

.stamps {
  height: 150px;
  /* width:150px; */
  float: right;
  border-radius: 5px;
  position: relative;
  animation-name: pulse;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  margin-right: 2rem;
  margin-top: 0rem;

  /* .terms-list li,
    .booking-details-listm li,
    .bank-details-list li {
        margin-bottom: 10px; 
        line-height: 1.5; 
        color: #555; 
        position: relative; 
        padding-left: 0.8em; 
        text-align: justify;
    } */
  /*     
    .terms-list li::before,
    .booking-details-listm li::before,
    .bank-details-list li::before {
        color: black; 
        font-size: 0.8em; 
        margin-right: 0.5em; 
        position: absolute; 
        left:0;
        top: 50%; 
        transform: translateY(-50%); 
    } */
}

.terms-list,
.booking-details-listm,
.bank-details-list {
  list-style-type: disc;
  padding: 0;
  /* Remove default padding */
  margin-left: 60px;
  /* Add margin for indentation */
}

.terms-list li,
.booking-details-listm li,
.bank-details-list li {
  margin-bottom: 10px;
  line-height: 1.5;
  color: #555;
  position: relative;
  padding-left: 0.8em;
  text-align: justify;
}

.terms-list li::before,
.booking-details-listm li::before,
.bank-details-list li::before {
  color: black;
  font-size: 0.8em;
  margin-right: 0.5em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.marasan {
  /* margin-left: 135px; */
  /* margin-right: 30px; */
  font-weight: bold;
  font-family: emoji;
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  padding: 2px 60px 2px 60px;
}

@media only screen and (max-width: 767px) {
  .banner-content-marasandra {
    padding: 0px 0px 0px 1px;
    margin-top: -4rem;
  }

  .seals {
    height: 70px;
    width: 70px;
  }

  .stamps {
    height: 70px;
    width: 70px;
    float: right;
    border-radius: 5px;
    position: relative;
    animation-name: pulse;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    margin-right: 10rem;
    margin-top: 0rem;
  }

  .terms-list,
  .booking-details-listm {
    list-style-type: disc;
    padding: 1px;
    margin-left: 9px;
  }

  .marasan {
    /* margin-left: 135px; */
    /* margin-right: 30px; */
    font-weight: bold;
    font-family: emoji;
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
    padding: 2px 2px 2px 2px;
  }

  .bank-details ul li {
    font-size: 16px;
  }

  .terms-list {
    font-size: 1em;
    /* Reduce font size on smaller screens */
  }
}

.marquee {
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 1.2em;
}

.property-card {
  margin: 20px auto;
  /* Center the card horizontally */
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 1);
  /* Custom shadow effect */
  max-width: 85%;
  /* Limit card width */
}

.property-card ul {
  list-style: none;
  padding: 0;
}

.property-card li {
  margin-bottom: 10px;
  font-size: 1.1em;
  font-weight: bold;
}

.property-card li svg {
  margin-right: 10px;
}

.blink {
  animation: blinker 4s linear infinite;
  color: red;
  font-family: sans-serif;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.booking-text {
  margin-top: 20px;
  /* font-size: larger; */
  color: red;
  text-align: center;
}

.booking-p {
  text-align: center;
}

.card-title {
  text-align: center;
  margin-bottom: 20px;
}

.card-title h2 {
  font-weight: bold;
  color: black;
  /* margin-bottom: 10px; Add margin below the title */
}

.title-divider {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 5px auto;
  /* Center the divider */
}

.exclusive-text {
  font-style: italic;
  margin-bottom: 15px;
  /* Space below the exclusive text */
}

.icon-list {
  list-style: none;
  padding: 0;
  margin-top: 15px;
  /* Add margin space between title and icon list */
}

.icon-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  font-size: 24px;
  margin-right: 10px;
  color: #4a98ec;
  /* Example of using Bootstrap's primary color */
}

.icon + span {
  font-size: 18px;
  color: #495057;
  /* Adjust text color as needed */
}

.amenity-img {
  display: block;
  margin: auto;
}

.price-chart-section {
  background-color: #f8f6fe;
  padding: 40px;
  border-radius: 8px;
  /* margin-top: 40px; */
  margin-bottom: 40px;
}

.price-table {
  width: 100%;
  border-collapse: collapse;
}

.price-table th,
.price-table td {
  border: 1px solid #24457b;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-family: emoji;
}

.price-table th {
  background-color: #a3afc2 !important;
  color: black;
  font-weight: bold;
  border-top: 2px solid #dee2e6 !important;
  border-bottom: 2px solid #dee2e6 !important;
}

.price-table td {
  background-color: rgb(255, 255, 255) !important;
  border-bottom: 2px solid #dee2e6 !important;
}

/* src/pages/MarasandraPage/styles/MarasandraPage.css */

.terms-conditions-section {
  background-color: #f8f9fa;
  /* Light background color */
  padding: 40px 0;
  /* Padding around the section */
}

.terms-conditions-section h2 {
  text-align: center;
  /* Center align the heading */
  margin-bottom: 30px;
  /* Space below the heading */
  color: #333;
  /* Dark text color */
}

.booking-details-list {
  list-style-type: disc;
  /* Set the list style to disc (bullet points) */
  margin: 20px;
  /* Add some margin for spacing */
  padding-left: 20px;
  /* Add padding to the left for indentation */
}

.booking-details-list li {
  margin-bottom: 10px;
  /* Add some space between list items */
}

.bank-details-section {
  background-color: #f0f0f0;
  padding: 40px 20px;
  margin-top: -3rem;
}

.bank-details {
  margin-bottom: 40px;
}

.bank-details h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.bank-details ul {
  list-style: none;
  padding: 0;
}

.bank-details ul li {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.6;
}

.bank-details ul li:before {
  content: "\2022";
  /* Bullet character */
  color: #24457b;
  /* Custom bullet color */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.project-intro {
  text-align: center;
  margin-bottom: 20px;
}

.project-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #24457b;
  margin-bottom: 10px;
}

.intro-subtitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #24457b;
  margin-top: 20px;
  margin-bottom: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

td {
  font-family: emoji;
}

strong {
  font-family: emoji;
}

/* extra */
