.containers {
  display: flex;
  flex-wrap: wrap; 
  padding-left: 110px;
  padding-right: 110px;
  justify-content: center; 
  gap: 16px; 
}

.img-container {
  padding: 18px;
  width: 32%;
  height: 310px; 
  box-sizing: border-box;
}

.img-container img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .containers {
    padding-left: 50px;
    padding-right: 50px;
  }

  .img-container {
    width: 45%; 
  }
}

@media screen and (max-width: 768px) {
  .containers {
    padding-left: 20px;
    padding-right: 20px;
  }

  .img-container {
    width: 100%;
    padding: 10px;
  }
}


@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    width: max-content;
  }
}
.loadMore-btn{
  margin: auto;
  color:  #093c94;
  display: flex;
  font-size: 31px;
  text-decoration: underline;
  padding-bottom: 28px;
  font-style: italic;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* padding: 10px 20px;
  font-size: 1em;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.arrow {
  margin-left: 15px;
  border: solid #093c94;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  /* padding-left: 5px; */
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}