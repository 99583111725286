.faq {
    padding: 0;
}

.banner2 {
    background-image: url('../../images/faq-banner.webp');
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 100px 0;
    position: relative;
    height: 350px;
}

.banner-content1 {
    /* background: rgba(0, 0, 0, 0.5); */
    display: inline-block;
    padding: 74px 0px 0px 10px;



}

@media only screen and (max-width:767px) {
    .banner-content1 {
        /* padding: 154px 0px 0px 42px; */

    }
}

.faq-section {
    padding: 40px 20px;
    background: #f7f7f7;
}

.faq-heading {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: bold;
    color: #24457b;
}

.faq-content {
    margin: 20px 0;
}

.faq-card {
    background: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.faq-card-body {
    cursor: pointer;
    padding: 20px;
    transition: background 0.3s ease;
    position: relative;
}

.faq-card-body:hover {
    background: #f1f1f1;
}

.faq-question {
    margin: 0;
    font-size: 1.25rem;

    color: #24457b;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle-icon {
    font-size: 1.25rem;
    margin-left: auto;
}

.show-answer {
    display: block;
    margin-top: 15px;
    font-size: 1rem;
    color: #555;
}

.hide-answer {
    display: none;
}

.contact-form {
    margin: 40px 0;
    /* background: #fff; */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.form-rows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.contacts-us {
    max-width: 900px;
    margin: 0 auto;
}

.contacts-form-container {
    margin-top: 70px;
    padding: 20px;
    /* margin-left: 18rem; */
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .faq-heading {
        font-size: 2rem;
    }

    .banner2 {
        background-image: url('../../images/faq-banner.webp');
        background-size: contain;
        background-position: center;
        color: white;
        text-align: center;
        padding: 0px 0;
        position: relative;
        height: 174px;
        background-repeat: no-repeat;
    }

    .faq-section {
        padding: 2px 20px;
        background: #f7f7f7;
    }

    .faq-card-body {
        padding: 15px;
    }

    .faq-content {
        margin-left: 0;
        padding: 0 15px;
    }

    .contact-form {
        margin-top: 20px;
    }

    .form-rows {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .contacts-us {
        max-width: 900px;
        margin: 0 auto;

    }
}

@media (max-width: 576px) {
    .banner-content1 {
        /* padding: 154px 0px 0px 42px; */
    }

    .faq-heading {
        font-size: 1.5rem;
    }

    .faq-card-body {
        padding: 10px;
    }

    .contact-form {
        padding: 0px 15px
    }
}